import {computePosition, autoUpdate} from '@floating-ui/dom';
import * as shared from './shared.js'

let currentlyOpenPopover = null; // Track the currently open popover
let cleanupAutoUpdate = null;
let hideTimeout = null; // Timeout for hiding the popover
let showTimeout = null; // Timeout for showing the popover
let isMouseInPopover = false; // Track whether the mouse is inside the popover

// Function to initialize the popover for all elements with the 'post' class
export function initPopover() {
    const popover = document.getElementById('popover');
    if (!popover) {
        return
    }
    const posts = document.querySelectorAll('.post');


    posts.forEach(post => {
        // Function to show the popover
        function showPopover() {
            // Clear any pending hide timeout to prevent flickering
            clearTimeout(hideTimeout);

            // Close the currently open popover if there's one
            if (currentlyOpenPopover && currentlyOpenPopover !== post) {
                hidePopover(currentlyOpenPopover);
            }

            // Set a delay before showing the popover
            showTimeout = setTimeout(() => {
                currentlyOpenPopover = post;
                popover.classList.remove('hidden');

                // Get the post ID from the data-post-id attribute
                const postId = post.getAttribute('data-post-id');
                const fqdn = shared.getFqdn(window.location.href)
                if (postId) {
                    const link = popover.querySelector('a');
                    link.href = `https://${fqdn}/admin/post/${postId}/edit`;
                } else {
                    console.error('Popover unable to get postId')
                }

                // Compute the popover's position relative to the current post element
                computePosition(post, popover, {
                    placement: 'top',
                }).then(({x, y}) => {
                    Object.assign(popover.style, {
                        left: `${x}px`,
                        top: `${y + 50}px`,
                    });
                });

                // Automatically update the popover position while it's open
                cleanupAutoUpdate = autoUpdate(post, popover, () => {
                    computePosition(post, popover, {
                        placement: 'top',
                    }).then(({x, y}) => {
                        Object.assign(popover.style, {
                            left: `${x}px`,
                            top: `${y + 50}px`,
                        });
                    });
                });
            }, 150); // Delay before showing the popover
        }

        // Function to hide the popover
        function hidePopover() {
            // Clear any pending show timeout to prevent unwanted show
            clearTimeout(showTimeout);

            // Set a delay before hiding the popover
            hideTimeout = setTimeout(() => {
                if (!isMouseInPopover) {
                    popover.classList.add('hidden');
                    currentlyOpenPopover = null;

                    // Cleanup auto-update when hiding
                    if (cleanupAutoUpdate) {
                        cleanupAutoUpdate();
                        cleanupAutoUpdate = null;
                    }
                }
            }, 150); // Delay before hiding the popover
        }

        // Show the popover when hovering over the post
        post.addEventListener('mouseenter', () => {
            showPopover();
        });

        // Hide the popover when moving the mouse away from the post (unless moving into the popover)
        post.addEventListener('mouseleave', () => {
            hidePopover();
        });

        // Track when the mouse enters the popover to prevent closing
        popover.addEventListener('mouseenter', () => {
            clearTimeout(hideTimeout); // Cancel hide if the mouse enters the popover
            isMouseInPopover = true;
        });

        // Track when the mouse leaves the popover to allow hiding
        popover.addEventListener('mouseleave', () => {
            isMouseInPopover = false;
            hidePopover(); // Start hiding after the mouse leaves the popover
        });

        // Close the popover when clicking outside of any .post or popover
        document.addEventListener('click', (event) => {
            const isClickInsidePost = post.contains(event.target);
            const isClickInsidePopover = popover.contains(event.target);

            // If click is outside the post and the popover, close the current popover
            if (!isClickInsidePost && !isClickInsidePopover) {
                hidePopover();
            }
        });

        // Prevent the popover from closing when clicking inside it
        popover.addEventListener('click', (event) => {
            event.stopPropagation();
        });
    });
}
