// Import any dependencies or modules needed for this part of the app
import './../../js/bootstrap.js';
import PQueue from 'p-queue';
import Cookie from 'js-cookie'
import delay from 'delay';
import * as shared from '../../js/shared.js'
import GLightbox from 'glightbox/src/js/glightbox.js';
import * as Share from 'sharer.js'
import 'glightbox/dist/css/glightbox.min.css';
import {initPopover} from '../../js/popover.js'
import LazyLoad from "vanilla-lazyload";

'use strict';

window.siteEnv = {
    ...window.siteEnv,
    gaClientIds: [],
    gaClientId: null,
    gaInitialized: false
};

// Initialize the variable
let ga4ReadyTriggered = false;


// Check for past `ga4Ready` events in dataLayer
if (window.dataLayer) {
    window.dataLayer.forEach(event => {
        if (event.event === 'ga4Ready') {
            ga4ReadyTriggered = true;
            console.log("Detected previous ga4Ready event in dataLayer.");
            handleGa4Ready(); // Handle if event is already there
        }
    });
}

// Check if dataLayer is initialized, otherwise create it
window.dataLayer = window.dataLayer || [];

// Override the push method of the dataLayer
let originalPush = window.dataLayer.push;

// Listen for push events to the dataLayer
window.dataLayer.push = function () {
    // Call the original push method to ensure GTM continues working
    originalPush.apply(window.dataLayer, arguments);

    // Check each pushed event
    for (var i = 0; i < arguments.length; i++) {
        if (arguments[i].event === 'ga4Ready') {
            ga4ReadyTriggered = true;
            console.log("GA4 Ready Event Detected: ga4ReadyTriggered set to true");
            handleGa4Ready(); // Call your handler when the event is found
        }
    }
};

// Event handler for GA4 ready
function handleGa4Ready() {
    console.log('GA4 is ready X');
    getGAClientId()
}

// Safely get the meta tag's content attribute
function getPinterestImageURL() {
    const metaTag = document.querySelector('meta[property="pinterest:image"]');

    // Check if the meta tag exists and has a content attribute
    if (metaTag && metaTag.content) {
        return metaTag.content;
    }

    // Return null or handle the case when the meta tag is not found
    return null;
}


window.initShareIt = function (shareProps) {

    const socialSitesOriginalColors = {
        'facebook': '',
        'twitter': '',
        'whatsapp': '#25d366',
        'email': '#e61d27',
        'linkedin': '#2867b2',
        'threads': '#000000',
        'pinterest': '#e61d27',
        'skype': '#0078d7'
    }

    Object.entries(socialSitesOriginalColors).forEach(([key, value]) => {
        socialSitesOriginalColors[key] = `#000`
    });
    const svgSize = 'w-8 h-8 md:w-8 md:h-8 lg:w-8 lg:h-8  lg:m-1.5 xl:m-2'
    const socialSites = {
        'facebook': '<svg class="hover:fill-facebook ' + svgSize + '" id="facebook-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero"><path d="m55.268 51.85 2.229-12.136h-12.972v-4.292c0-6.414 2.516-8.88 9.028-8.88 2.023 0 3.651.049 4.588.148v-11.001c-1.776-.494-6.117-.987-8.633-.987-13.271 0-19.388 6.265-19.388 19.782v5.23h-8.189v12.136h8.189v26.408c3.073.762 6.285 1.17 9.594 1.17 1.629 0 3.234-.101 4.811-.291v-27.287z" fill="#fff"/><path d="m79.428 39.714c0-21.934-17.781-39.714-39.714-39.714-21.934 0-39.714 17.78-39.714 39.714 0 18.625 12.822 34.252 30.12 38.544v-26.408h-8.189v-12.136h8.189v-5.23c0-13.517 6.117-19.782 19.388-19.782 2.516 0 6.857.493 8.633.987v11.001c-.937-.099-2.565-.148-4.588-.148-6.512 0-9.028 2.467-9.028 8.88v4.292h12.972l-2.228 12.136h-10.744v27.287c19.664-2.375 34.903-19.119 34.903-39.423"/></g></svg>',
        'twitter': '<svg class="hover:fill-twitter ' + svgSize + '" id="twitter-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 78 80" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero"><path d="m75.22 76.873-51.066-74.318h-21.654l51.066 74.318z" fill="#fff"/><path d="m46.254 33.632 28.933-33.632h-6.857l-25.122 29.202-20.065-29.202h-23.143l30.343 44.159-30.343 35.269h6.857l26.529-30.839 21.191 30.839h23.143l-31.468-45.796zm-9.391 10.916-3.075-4.397-24.461-34.989h10.531l19.741 28.237 3.074 4.397 25.661 36.705h-10.532l-20.939-29.951z"/></g></svg>',
        'whatsapp': '<svg class="hover:fill-whatsapp ' + svgSize + '" id="whatsapp-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg"><path d="m66.997 12.461c-7.238-7.248-16.865-11.241-27.122-11.244-21.134 0-38.335 17.198-38.343 38.339-.003 6.758 1.764 13.355 5.119 19.168l-5.44 19.87 20.326-5.332c5.6 3.056 11.906 4.665 18.322 4.667h.016c21.132 0 38.335-17.201 38.342-38.342.003-10.245-3.981-19.877-11.22-27.125z" fill="#fff"/><path d="m67.855 11.598c-7.466-7.475-17.395-11.594-27.974-11.598-21.8 0-39.542 17.74-39.549 39.545-.004 6.971 1.818 13.775 5.279 19.771l-5.611 20.495 20.965-5.5c5.776 3.152 12.281 4.811 18.899 4.813h.017c21.795 0 39.539-17.741 39.547-39.547.003-10.568-4.106-20.502-11.573-27.977v-.002zm-27.974 60.847h-.014c-5.897-.001-11.683-1.587-16.73-4.581l-1.201-.713-12.441 3.264 3.32-12.131-.781-1.244c-3.29-5.234-5.029-11.283-5.025-17.493.007-18.123 14.753-32.868 32.885-32.868 8.78.003 17.032 3.426 23.239 9.64 6.206 6.213 9.622 14.473 9.618 23.254-.008 18.125-14.753 32.871-32.87 32.871zm18.029-24.617c-.988-.495-5.846-2.884-6.752-3.214-.907-.33-1.565-.495-2.223.495s-2.552 3.216-3.128 3.874c-.577.66-1.153.741-2.141.246s-4.172-1.538-7.948-4.904c-2.937-2.62-4.921-5.855-5.497-6.845s-.061-1.525.432-2.016c.444-.444.988-1.155 1.483-1.731.495-.577.658-.99.988-1.648.33-.66.165-1.236-.081-1.731-.247-.495-2.223-5.359-3.048-7.337-.802-1.927-1.617-1.665-2.222-1.697-.577-.029-1.234-.034-1.894-.034s-1.73.246-2.636 1.236c-.907.99-3.459 3.381-3.459 8.243s3.54 9.563 4.035 10.223 6.969 10.64 16.881 14.922c2.357 1.018 4.198 1.627 5.634 2.082 2.366.753 4.52.647 6.223.393 1.899-.285 5.847-2.392 6.669-4.699.823-2.308.823-4.288.577-4.699-.246-.412-.906-.66-1.894-1.155z"/></svg>',
        'email': '<svg class="hover:fill-email ' + svgSize + '" id="email-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 90 80" xmlns="http://www.w3.org/2000/svg"><g><path d="m10.126 9.417c-2.612 2.391-4.362 6.27-4.362 12.355v36.266c0 6.085 1.75 9.964 4.362 12.355 2.652 2.426 6.646 3.738 11.968 3.738h45.765c5.322 0 9.317-1.312 11.968-3.738 2.613-2.391 4.363-6.27 4.363-12.355v-36.266c0-6.085-1.75-9.964-4.363-12.355-2.651-2.426-6.646-3.737-11.968-3.737h-45.765c-5.322 0-9.316 1.311-11.968 3.737zm-3.922-4.162c4.073-3.727 9.685-5.255 15.89-5.255h45.765c6.206 0 11.817 1.528 15.89 5.255 4.112 3.763 6.205 9.35 6.205 16.517v36.266c0 7.168-2.093 12.755-6.205 16.517-4.073 3.727-9.684 5.256-15.89 5.256h-45.765c-6.205 0-11.817-1.529-15.89-5.256-4.111-3.762-6.204-9.349-6.204-16.517v-36.266c0-7.167 2.093-12.754 6.204-16.517z"/><path d="m76.743 19.673c1.093 1.414.832 3.446-.582 4.539l-23.93 18.491c-4.273 3.302-10.235 3.302-14.508 0l-23.931-18.491c-1.414-1.093-1.674-3.125-.582-4.539 1.093-1.414 3.125-1.674 4.539-.582l23.93 18.492c1.943 1.5 4.653 1.5 6.595 0l23.931-18.492c1.414-1.092 3.446-.832 4.538.582z"/></g></svg>',
        'linkedin': '<svg class="hover:fill-linkedin ' + svgSize + '" id="linkedin-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 96 80" xmlns="http://www.w3.org/2000/svg"><path d="m5.887 6.166h68.04v67.479h-68.04z" fill="#fff"/><g fill="#fill-color#" fill-rule="nonzero"><path d="m68.023 68.021h-11.998v-20.819c0-6.142-2.612-8.037-5.98-8.037-3.56 0-7.05 2.68-7.05 8.19v20.666h-11.999v-38.117h11.539v5.281h.153c1.161-2.347 5.214-6.353 11.405-6.353 6.695 0 13.93 3.972 13.93 15.615zm-50.235-43.428c-3.82 0-6.917-2.908-6.917-6.887s3.097-6.891 6.917-6.891c3.821 0 6.918 2.912 6.918 6.891s-3.097 6.887-6.918 6.887zm6 43.399h-11.999v-38.117h11.999zm50.133-67.992h-68.029c-3.254 0-5.892 2.638-5.892 5.892v68.027c0 3.254 2.638 5.892 5.892 5.892h68.029c3.254 0 5.892-2.638 5.892-5.892v-68.027c0-3.254-2.638-5.892-5.892-5.892z"/><path d="m87.482 65.172v-2.188h1.912c.834 0 1.31.382 1.31 1.051 0 .711-.491 1.137-1.31 1.137zm2.902.796c.948-.293 1.516-1.036 1.516-1.985 0-1.271-.866-2.032-2.315-2.032h-3.265v6.706h1.162v-2.553h1.475l1.798 2.553h1.431l-1.893-2.661zm-1.468 5.036c-3.195 0-5.699-2.503-5.699-5.7 0-1.191.347-2.285.949-3.19 1.013-1.524 2.746-2.509 4.75-2.509 3.196 0 5.701 2.503 5.701 5.699 0 3.197-2.505 5.7-5.701 5.7zm0-12.406c-3.772 0-6.704 2.944-6.704 6.706 0 3.763 2.932 6.705 6.704 6.705 3.775 0 6.707-2.942 6.707-6.705 0-3.762-2.932-6.706-6.707-6.706z"/></g></svg>',
        'threads': '<svg class="hover:fill-threads ' + svgSize + '" id="threads-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 71 80" xmlns="http://www.w3.org/2000/svg"><path d="m35.651 79.811h-.047c-11.908-.08-21.063-4.007-27.216-11.669-5.467-6.823-8.292-16.313-8.388-28.205v-.056c.096-11.899 2.921-21.381 8.396-28.205 6.145-7.67 15.308-11.596 27.208-11.676h.047c9.131.064 16.769 2.41 22.699 6.975 5.578 4.294 9.505 10.408 11.668 18.181l-6.784 1.892c-3.671-13.169-12.961-19.897-27.615-20.001-9.673.072-16.991 3.113-21.748 9.035-4.445 5.547-6.744 13.56-6.832 23.823.088 10.264 2.387 18.277 6.84 23.824 4.757 5.93 12.075 8.971 21.748 9.034 8.724-.063 14.494-2.099 19.291-6.8 5.475-5.363 5.379-11.947 3.623-15.954-1.029-2.362-2.905-4.325-5.435-5.818-.638 4.493-2.067 8.133-4.27 10.878-2.945 3.664-7.119 5.667-12.402 5.954-3.999.216-7.854-.726-10.839-2.665-3.535-2.291-5.602-5.787-5.826-9.857-.215-3.959 1.357-7.598 4.422-10.248 2.929-2.53 7.047-4.014 11.915-4.294 3.584-.199 6.944-.039 10.041.471-.415-2.466-1.246-4.429-2.491-5.842-1.708-1.947-4.349-2.937-7.845-2.961h-.096c-2.809 0-6.624.774-9.05 4.39l-5.842-3.919c3.256-4.836 8.539-7.502 14.892-7.502h.144c10.623.064 16.952 6.568 17.582 17.917.359.152.719.312 1.07.471 4.956 2.331 8.579 5.858 10.487 10.208 2.65 6.058 2.897 15.93-5.148 23.808-6.153 6.017-13.616 8.739-24.199 8.811zm3.313-38.876c-.807 0-1.621.024-2.459.072-6.105.343-9.912 3.144-9.697 7.127.224 4.174 4.829 6.113 9.258 5.874 4.071-.216 9.37-1.804 10.264-12.339-2.251-.487-4.717-.734-7.366-.734z" fill-rule="nonzero"/></svg>',
        'pinterest': '<svg class="hover:fill-pinterest ' + svgSize + '" id="pinterest-icon" fill="#fill-color#" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero"><path d="m79.792 39.915c0 22.034-17.862 39.896-39.896 39.896s-39.896-17.862-39.896-39.896 17.862-39.896 39.896-39.896 39.896 17.862 39.896 39.896z" fill="#fff"/><path d="m25.085 76.949c-.499-4.538-.15-8.877.847-13.066l3.99-17.205c-.648-2.094-1.147-4.488-1.147-6.882 0-5.585 2.693-9.575 6.932-9.575 2.942 0 5.087 2.045 5.087 5.985 0 1.246-.25 2.643-.748 4.238l-1.746 5.736c-.349 1.097-.499 2.144-.499 3.042 0 3.989 3.043 6.233 6.932 6.233 6.932 0 11.87-7.181 11.87-16.507 0-10.372-6.783-17.005-16.807-17.005-11.171 0-18.252 7.281-18.252 17.404 0 4.09 1.247 7.88 3.69 10.473-.798 1.347-1.645 1.596-2.942 1.596-3.99 0-7.78-5.636-7.78-13.315 0-13.266 10.622-23.838 25.534-23.838 15.659 0 25.483 10.921 25.483 24.386 0 13.565-9.575 23.738-19.898 23.738-4.089 0-7.68-1.745-10.174-4.887l-2.044 8.279c-1.097 4.288-2.743 8.328-5.386 12.217 3.74 1.148 7.43 1.796 11.869 1.796 22.043 0 39.896-17.853 39.896-39.896s-17.853-39.896-39.896-39.896-39.896 17.853-39.896 39.896c0 16.707 10.273 31.119 25.085 37.053z" fill="#fill-color#"/></g></svg>',
        'skype': '<svg  class="hover:fill-skype ' + svgSize + '" id="skype-icon" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 80 81" xmlns="http://www.w3.org/2000/svg"><circle cx="39.89" cy="40.125" fill="#fff" r="29.843"/><path d="m76.859 46.679c.372-2.128.566-4.318.566-6.556 0-20.843-16.804-37.743-37.53-37.743-2.225 0-4.402.193-6.518.567-3.259-1.878-7.037-2.947-11.064-2.947-12.323 0-22.313 10.049-22.313 22.443 0 4.047 1.063 7.848 2.933 11.127-.374 2.126-.566 4.317-.566 6.553 0 20.845 16.804 37.747 37.528 37.747 2.226 0 4.403-.196 6.52-.569 3.26 1.878 7.037 2.948 11.061 2.948 12.324 0 22.316-10.048 22.316-22.444 0-4.048-1.067-7.847-2.933-11.126m-36.81 17.587c-13.334 0-19.425-6.787-19.425-11.774 0-2.558 2.002-4.317 4.564-4.317 5.704 0 4.219 8.557 14.861 8.557 5.447 0 8.637-3.277 8.637-6.351 0-1.851-1.061-3.908-4.647-4.807l-11.856-3.006c-9.547-2.426-11.221-7.739-11.221-12.663 0-10.227 9.347-13.923 18.258-13.923 8.202 0 17.954 4.525 17.954 10.663 0 2.629-2.205 4.059-4.768 4.059-4.869 0-4.058-6.848-13.864-6.848-4.869 0-7.436 2.279-7.436 5.482 0 3.195 3.766 4.279 7.103 5.051l8.774 1.979c9.613 2.171 12.184 7.807 12.184 13.174 0 8.311-6.436 14.724-19.118 14.724" fill="#fill-color#" fill-rule="nonzero" class="hover:fill-skype" /></svg>'
    }

    shareProps.image = getPinterestImageURL()

    let links = []

    Object.entries(socialSites).forEach(([key, value]) => {
        links.push(`<a data-sharer="${key}" rel="noopener noreferrer" data-title=">${shareProps.title}" href="#" class="block">${value.replace('#fill-color#', socialSitesOriginalColors[key])}</a>`)
    });

    document.querySelector('#share-it').innerHTML = links.join('\n')
    //<button class="button" data-sharer="evernote" data-url="https://ellisonleao.github.io/sharer.js/" data-title="Checkout Sharer.js!">Share on Evernote</button>

    window.Sharer.init();
}

window.initGallery = function () {

    // let elements = [];
    // images.forEach(element => {
    //     elements.push({
    //         'href': '/thumbs/' + element,
    //         'type': 'image',
    //     })
    // });
    return GLightbox({
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
        selector: '.gallery-item'
    })
}

// Create a new queue instance with concurrency set to 1 (adjust as needed)
const queue = new PQueue({concurrency: 1, autoStart: false});
const queeDelay = 100;
queue.pause();
const queueStandard = new PQueue({concurrency: 1, autoStart: false});
queueStandard.pause();

// JavaScript to toggle mobile menu
document.getElementById('nav-toggle').addEventListener('click', function () {
    let mobileMenu = document.getElementById('mobile-menu');
    if (mobileMenu.classList.contains('hidden')) {
        mobileMenu.classList.remove('hidden');
    } else {
        mobileMenu.classList.add('hidden');
    }
});

// Function to check if GA4 is loaded
function isGA4Loaded() {
    return ga4ReadyTriggered;  // Return the updated flag directly
}

// section GET GA CLIENT ID
function getGAClientId() {
    if (window.siteEnv.gaClientId !== null) {
        console.log('getGAClientId exist', window.siteEnv);
        window.siteEnv.gaInitialized = true
        return window.siteEnv.gaClientId;
    }
    console.log('getGAClientId continue', window.siteEnv);

    if (isGA4Loaded()) {
        console.log("isGA4Loaded() check: GA4 is loaded.");
    } else {
        console.log("isGA4Loaded() check: GA4 is not loaded.");
    }

    // Directly check ga4ReadyTriggered for consistency
    if (ga4ReadyTriggered) {
        console.log("Direct flag check: GA4 is loaded, proceeding with client ID.");
        // Retrieve and use the client ID or perform GA4 operations here
    } else {
        console.log("Direct flag check: GA4 is not loaded yet.");
    }

    // Ensure the gtag function is available and GA4 is configured
    if (isGA4Loaded()) {
        console.log("isGA4Loaded() check: GA4 is loaded.");

        // Retry until gtag is defined
        const checkGtagInterval = setInterval(() => {
            if (typeof gtag === 'function') {
                clearInterval(checkGtagInterval);

                gtag('get', window.siteEnv.GA4_ID, 'client_id', function (gaClientId) {
                    console.log('Get GA Client ID:', gaClientId)
                    storeGA4ClientId(gaClientId);
                    // Send GA Client ID to your backend
                    setTimeout(() => {

                        console.log('GA Client ID:', gaClientId)
                        window.siteEnv.gaInitialized = true;
                        console.log('GA4 initialized');
                        window.siteEnv.gaClientId = gaClientId
                        sendGaIdentifierToServer(gaClientId).then(() => {
                            console.log('All done');
                            queue.start();
                            console.log('getting likes');
                            getUserPostLikes()
                        })
                            .catch(error => {
                                console.warn('Error sendGaIdentifierToServer:');
                            });
                    }, 100);
                });
            } else {
                console.log('Waiting for gtag to be defined...');
            }
        }, 100);  // Check every 100ms
    } else {
        window.siteEnv.gaInitialized = false
        window.siteEnv.gaClientId = null;
        console.warn('Google Analytics or GTM not initialized yet.');
    }
}

function findUpwardOrSiblingWithClass(element, targetClass) {
    let currentElement = element;

    while (currentElement) {
        // Check if the current element has the target class
        if (currentElement.classList.contains(targetClass)) {
            return currentElement;
        }

        // Check siblings at the current level
        let sibling = currentElement.parentElement?.firstElementChild;
        while (sibling) {
            if (sibling !== currentElement && sibling.classList.contains(targetClass)) {
                return sibling; // Return sibling if it has the target class
            }
            sibling = sibling.nextElementSibling;
        }

        // Move up to the next parent level
        currentElement = currentElement.parentElement;
    }

    // If no element is found, return null
    return null;
}

function removeContentPreloader(findInElement) {
    return new Promise((resolve) => {
        const element = findUpwardOrSiblingWithClass(findInElement, 'content-preloader')
        if (element) {
            element.classList.add('content-preloader-fade-out')
            // element for the transition end event
            element.addEventListener('transitionend', () => {
                element.classList.add('hidden'); // Hides the element after transition
                resolve();
            }, {once: true}); // 'once: true' ensures the event is handled only once
        } else {
            resolve(); // Resolve immediately since there's no animation here
        }

    });
}

// section GET POSTS USAGES
function getPostsUsages() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post-sale, div.post-sale-cupon');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-usages';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsUsages' in res.data) {
                        Object.entries(res.data.postsUsages).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-usages`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content
                                const parentSpan = postElement.parentElement;
                                removeContentPreloader(parentSpan).then(() => {
                                    parentSpan.classList.remove('opacity-0')
                                    parentSpan.classList.add('post-info-fade-in')
                                });
                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsLikes');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(queeDelay))
}

// section GET POSTS VIEWS
function getPostsViews() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-views';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsViews' in res.data) {
                        Object.entries(res.data.postsViews).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-views`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content
                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsViews:');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(queeDelay))
}

// section GET POSTS LIKES
function getPostsLikes() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-likes';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsLikes' in res.data) {
                        Object.entries(res.data.postsLikes).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-likes`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content
                                let parentSpan = postElement.parentElement
                                removeContentPreloader(parentSpan).then(() => {
                                    if (!parentSpan.classList.contains('opacity-0')) {
                                        parentSpan = parentSpan.closest('.post-counters')
                                    }
                                    if (parentSpan) {
                                        parentSpan.classList.remove('opacity-0')
                                        parentSpan.classList.add('post-info-fade-in')
                                    }
                                });
                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsLikes');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(queeDelay))
}

// section GET POSTS DISMIS TIMES
function getPostsDismissTimes() {
    // Select all <div> elements with the class 'post'
    const postElements = document.querySelectorAll('div.post-sale, div.post-sale-cupon');
    // Map the NodeList to an array of data-post-id values
    const postIds = Array.from(postElements).map(element => element.getAttribute('data-post-id'));
    if (!postIds.length) {
        return
    }
    const url = '/api/get-posts-dismiss-times';
    const data = {ids: postIds}

    queueStandard.add(async () => {
        shared.sendData(url, data).then(res => {
                if (res.success) {
                    if ('postsDismissTimes' in res.data) {
                        Object.entries(res.data.postsDismissTimes).forEach(([id, value]) => {
                            const postElement = document.querySelector(`[data-post-id="${id}"] .post-tte`);
                            // Check if the element exists before updating
                            if (postElement) {
                                postElement.textContent = value.toString(); // Change the text content

                                const parentSpan = postElement.parentElement
                                parentSpan.classList.remove('opacity-0')
                                parentSpan.classList.add('post-info-fade-in')

                            }
                        });
                    }
                }
            }
        ).catch(error => {
            console.warn('Failed to get getPostsViews:');
            // throw new Error('Response was not successful');
        });
    });
    queueStandard.add(() => delay(queeDelay))
}

function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    console.log(value);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

// Function to send GA Client ID to your backend
async function sendGaIdentifierToServer(gaClientId) {
    const url = '/api/store-user-identifier';
    const uuid = Cookie.get('uuid') || localStorage.getItem('uuid');

    const data = {gaClientId: gaClientId, gaClientIds: getGAClientIds(), uuid: uuid}
    const consetGranted = true;

    return shared.sendData(url, data).then(res => {
            if (res.success) {
                if (consetGranted) {
                    if (res.userIdentifier.ga_client_id) {
                        console.log('Setting gaClientIds, userIdentifier')
                        // localStorage.removeItem('gaClientIds');
                        localStorage.setItem('userIdentifier', res.userIdentifier.ga_client_id);
                    }
                    if (uuid) {
                        console.log('Setting uuid')
                        localStorage.setItem('uuid', uuid);
                    }
                }
                console.log(res)
            }
        }
    ).catch(error => {
        console.warn('Failed to store ga_client_id');
        // throw new Error('Response was not successful');
    });
}

// section MAKE LIKED
function makeLiked(element) {
    if (!element) {
        return
    }
    let classes = []
    element.classList.forEach(className => {
        if (className.startsWith('hover:')) {
            element.classList.remove(className);
            let classAfterHover = className.split(':')[1]; // Extract the class after 'hover:'
            if (classAfterHover.startsWith('text-') || classAfterHover.startsWith('bg-')) {
                classes.push(classAfterHover)
            }
        }
        if (className.startsWith('bg-') || className.startsWith('text-sl')) {
            element.classList.remove(className);
        }
    });
    element.classList.add('liked');
    // adnimate only loaded likes
    if (!element.classList.contains('like-loading')) {
        element.classList.add('animate-ping');
        setTimeout(() => {
            element.classList.remove('animate-ping');
        }, 1000);
    }


    element.classList.remove('like-loading');
    element.removeEventListener('click', onLikeClick)

}

// section ON LIKE CLICK
function onLikeClick(event) {
    event.preventDefault(); // Prevent the default anchor behavior
    event.stopPropagation();

    makeLiked(event.target.closest('.like'))
    // Find the closest parent 'post' div to get the post ID
    const postDiv = this.closest('.post');
    const postId = postDiv.getAttribute('data-post-id');
    const url = '/api/store-post-like';

    // Add to queue
    queue.add(async () => {
        // Wait for ga_client_id to be available before sending
        //data.ga_client_id = await getGAClientId();
        const data = {postId: postId, gaClientId: getGAClientId()}

        // Call shared.sendData after ga_client_id is set
        return shared.sendData(url, data)
            .then(result => {
                console.log('Data sent successfully:', result);
            })
            .catch(error => {
                console.warn('Error sending data:');
            });
    });
}

// section ON COPY CODE  CLICK
function onCuponUsageClick(element, event) {
    event.preventDefault();
    event.stopPropagation();

    // Find the closest parent 'post' div to get the post ID
    const postDiv = element.closest('.post');
    const postId = postDiv.getAttribute('data-post-id');
    const url = '/api/store-post-usage';

    // Add to queue
    queue.add(async () => {
        // Wait for ga_client_id to be available before sending
        //data.ga_client_id = await getGAClientId();
        const data = {postId: postId, gaClientId: getGAClientId()}

        // Call shared.sendData after ga_client_id is set
        return shared.sendData(url, data)
            .then(result => {
                console.log('Data sent successfully:', result);
            })
            .catch(error => {
                console.warn('Error sending data:');
            });
    });
}

function makeLikedByIds(idsArray) {
    console.log('makeLikedByIds')
    idsArray.forEach(id => {
        // Find divs with the data-post-id attribute equal to the current ID
        const div = document.querySelector(`div[data-post-id="${id}"]`);

        if (div) {
            // Find children with the .like class within the found div
            const likeChildren = div.querySelectorAll('.like');
            likeChildren.forEach(child => {
                // Call the makeLiked method with each .like child element
                makeLiked(child);
            });
        }
    });
}

// section GET USER POST LIKES
function getUserPostLikes() {
    const url = '/api/get-user-post-likes';
    const data = {gaClientId: getGAClientId()}

    queue.add(async () => {
        console.log('getUserPostLikes')
        shared.getdData(url, data).then(res => {
                if (res.success) {
                    console.log('get user likes')
                    makeLikedByIds(res.data.likedPosts)
                    // change like button in navbar to indicate user likes
                    if (res.data.likedPosts.length) {
                        console.log('use rhave likes')
                        const likesButton = document.querySelector('.likes-btn');
                        if (likesButton) {
                            likesButton.classList.remove('likes-btn-likes')
                            likesButton.classList.add('likes-btn-liked')
                        }
                    }

                    const likeButtons = document.querySelectorAll('.post .like:not(.liked)');
                    likeButtons.forEach(function (element) {
                        element.addEventListener('click', onLikeClick)
                        element.classList.remove('like-loading');
                    });
                }
            }
        ).catch(error => {
            console.warn('Failed to get getUserPostLikes');
            // throw new Error('Response was not successful');
        });
    });
}

function getGAClientIds() {
    // Retrieve stored IDs from localStorage (if any)
    return JSON.parse(localStorage.getItem('gaClientIds')) || [];
}

// Store GA4 Client ID
function storeGA4ClientId(clientId) {

    let storedIds = getGAClientIds();
    // Add the new client ID only if it doesn't already exist in the array
    if (clientId && !storedIds.includes(clientId)) {
        storedIds.push(clientId);
    }

    // Save the updated array back to localStorage
    localStorage.setItem('gaClientIds', JSON.stringify(storedIds));
}

const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
        return navigator.clipboard.writeText(str);
    return Promise.reject('The Clipboard API is not available.');
};

function attachCopyCode(trigger, text) {
    if ('clipboard' in navigator) {
        trigger.addEventListener('click', (event) => {
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log('Text copied');
                    onCuponUsageClick(trigger, event)
                })
                .catch((err) => console.error(err.name, err.message));
        });
    } else {
        trigger.addEventListener('click', (event) => {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.opacity = 0;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const success = document.execCommand('copy');
                console.log(`Text copy was ${success ? 'successful' : 'unsuccessful'}.`);
                onCuponUsageClick(trigger, event)
            } catch (err) {
                console.error(err.name, err.message);
            }
            document.body.removeChild(textArea);
        });
    }
}

function affiliateLinks() {
    const posts = document.querySelectorAll('.post');

    posts.forEach(post => {

        const saleBox = post.querySelector('.sale-box');

        if (saleBox) {
            let targets = [];
            // Attach the click event listener to the sale-cupon element

            // '1' => trans('nej.fields.aff_url_open_on_copy'),
            //     '2' => trans('nej.fields.aff_url_open_on_show'),
            //     '3' => trans('nej.fields.aff_url_open_always'),
            //      '4' => trans('nej.fields.aff_url_open_on_show_and_copy'),

            const affiliateUrl = saleBox.getAttribute('data-affiliate-url')

            const affiliateUrlAction = parseInt(saleBox.getAttribute('data-affiliate-action'))


            const ahref = saleBox.querySelector('a')
            let hrefUrl = null
            if (ahref) {
                hrefUrl = saleBox.querySelector('a').href
            } else {
                hrefUrl = window.location
            }

            let clickTarget = null;
            const copyElement = post.querySelector('.copy-cupon')
            let openOnCopy = false
            let openOnShow = false
            switch (affiliateUrlAction) {
                case 1:
                    // openOnShow = false
                    openOnCopy = true
                    break;
                case 2:
                    openOnShow = true
                    clickTarget = saleBox
                    // openOnCopy = false
                    break;
                case 3:
                    clickTarget = post
                    openOnShow = true
                    openOnCopy = true
                    break;
                case 4:
                    clickTarget = saleBox
                    openOnCopy = true
                    break;
                default:
                    clickTarget = null
                    break
            }

            if (openOnCopy && copyElement) {
                if (openOnCopy) {
                    targets.push(copyElement);
                }
            }

            if (copyElement) {
                const affCode = saleBox.getAttribute('data-affiliate-code')
                if (affCode) {
                    attachCopyCode(copyElement, affCode)
                }
            }


            if (clickTarget !== null) {
                targets.push(clickTarget)
            }

            if (targets.length) {

                targets.forEach(target => {
                        target.addEventListener('click', function (event) {
                            event.preventDefault(); // Prevent default if needed
                            // Open the current page in a new tab
                            window.open(hrefUrl, '_blank');
                            // Redirect the current tab to the new URL
                            window.location.href = affiliateUrl;
                        });
                    }
                );


            }
        }
    });

}

// Log the initial state before GA4 is ready
console.log('Initial state: ', ga4ReadyTriggered ? 'GA4 ready' : 'GA4 NOT ready');

document.addEventListener('DOMContentLoaded', function () {
    console.log('DOM fully loaded and parsed');
    setTimeout(() => {
        console.log('start get requests')
        getPostsUsages()
        getPostsViews()
        getPostsDismissTimes()
        getPostsLikes()

        queueStandard.start()
    }, 500);
    setTimeout(() => {
        if (typeof shareProps !== 'undefined') {

            window.initShareIt(shareProps)
        }
        affiliateLinks()
    }, 500);

    // var lazyLoadInstance = new LazyLoad({
    //     // Your custom settings go here
    // });


    initPopover();
});
(function () {
    function logElementEvent(eventName, element) {
        console.log(Date.now(), eventName, element.getAttribute("data-src"));
    }

    var callback_enter = function (element) {
        logElementEvent("🔑 ENTERED", element);
    };
    var callback_exit = function (element) {
        logElementEvent("🚪 EXITED", element);
    };
    var callback_loading = function (element) {
        logElementEvent("⌚ LOADING", element);
    };
    var callback_loaded = function (element) {
        logElementEvent("👍 LOADED", element);
    };
    var callback_error = function (element) {
        logElementEvent("💀 ERROR", element);
        element.setAttribute("srcset", "/images/error.webp");
        element.setAttribute("src", "/images/error.webp");
    };
    var callback_finish = function () {
        logElementEvent("✔️ FINISHED", document.documentElement);
    };
    var callback_cancel = function (element) {
        logElementEvent("🔥 CANCEL", element);
    };

    let ll = new LazyLoad({
        // Assign the callbacks defined above
        callback_enter: callback_enter,
        callback_exit: callback_exit,
        callback_cancel: callback_cancel,
        callback_loading: callback_loading,
        callback_loaded: callback_loaded,
        callback_error: callback_error,
        callback_finish: callback_finish
    });
})();

// Function to get the current Tailwind breakpoint
function getBreakpoint() {
    const width = window.innerWidth;

    if (width >= 1536) return '2XL';
    if (width >= 1280) return 'XL';
    if (width >= 1024) return 'LG';
    if (width >= 768) return 'MD';
    if (width >= 640) return 'SM';
    return '- ';
}

// Function to update button content
function updateSizeIndicator() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const breakpoint = getBreakpoint();
    const pixelDensity = window.devicePixelRatio || 1;
    // Update the button content
    document.getElementById('size-indicator').textContent = `${width} x ${height} @ ${pixelDensity} | ${breakpoint}`;
}

// Initial update
updateSizeIndicator();

// Update on resize
window.addEventListener('resize', updateSizeIndicator);
